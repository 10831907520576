<template>
  <div class="bg-white">
    <div v-show="isLoading">
      <OtherLoading />
    </div>
    <div v-show="!isLoading" class="p-3 pre-box title-panel">
      <b-row>
        <b-col cols="4" class="border-right">
          <label>Earn Point</label>
          <div>
            <span class="text-score">
              {{ analysis.point | numeral("0,0") }} ({{
                analysis.percent_point
              }}%)
            </span>
            <!-- <span>Time(s)</span> -->
          </div>
        </b-col>
        <b-col cols="4" class="border-right">
          <label>Revenue</label>
          <div>
            <span class="text-score">
              {{ analysis.revenue | numeral("0,0") }}
              ({{ analysis.percent_revenue }}%)
            </span>
            <!-- <span>Time(s)</span> -->
          </div>
        </b-col>
        <b-col cols="4">
          <label>Customer</label>
          <div>
            <span class="text-score">
              {{ analysis.customer | numeral("0,0") }}
              ({{ analysis.percent_customer }}%)
            </span>
            <!-- <span>Time(s)</span> -->
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="pl-3 pr-3 pt-3">
      <b-input-group>
        <b-form-input
          class="search-bar"
          @keyup.enter="getDetail"
          placeholder="Search Name, Tel., Email"
          v-model="filter.search"
        >
        </b-form-input>
        <b-input-group-append is-text class="mr-2">
          <b-iconstack font-scale="2" type="submit" @click="getDetail">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
        <b-button class="btn button btn-mobile px-4" @click="exportData">
          <font-awesome-icon icon="file-export" class="pointer" />
          <span class="btn-text">Export </span>
        </b-button>
      </b-input-group>
    </div>

    <div class="p-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="formartedItems"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(invoice_no)="data">
          <div>
            <template v-if="data.item.invoice_no">
              <router-link
                :to="'/report/transaction/' + data.item.user_transaction_id"
              >
                <b-button variant="link" class="name-link p-0">
                  {{ data.item.invoice_no }}
                </b-button>
              </router-link>
            </template>
            <div v-else>
              {{ "-" }}
            </div>
          </div>
        </template>
        <template v-slot:cell(member_id)="data">
          <div v-if="data.item.customer_name">
            <router-link :to="'/report/customer/' + data.item.user_guid">
              <u>{{ data.item.member_id }}</u>

              <br />

              {{ data.item.customer_name }}
            </router-link>
          </div>
          <div v-else>
            <p class="m-0">-</p>
          </div>
        </template>

        <template v-slot:cell(created_time)="data">
          <div class="text-center">
            <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ data.item.created_time | moment("HH:mm:ss") }}
            </div>
          </div>
        </template>
        <template v-slot:cell(grand_total)="data">
          <div class="text-center">
            <div>{{ data.item.grand_total | numeral("0,0") }}</div>
          </div>
        </template>
        <template v-slot:cell(extra_point)="data">
          <div class="text-center">
            <div>{{ data.item.extra_point | numeral("0,0") }}</div>
          </div>
        </template>
        <template v-slot:cell(point)="data">
          <div class="text-center">
            <div>{{ data.item.point | numeral("0,0") }}</div>
          </div>
        </template>
        <template v-slot:cell(user_transaction_status_id)="data">
          <div>
            <div v-if="data.item.deleted === 1" class="text-danger">
              Deleted
            </div>
            <div v-else-if="data.item.user_transaction_status_id === 1">
              <span class="text-success">Complete </span>
              <span class="status-void" v-if="data.item.is_void === 1"
                >(Void/Returned)</span
              >
            </div>
            <div
              v-else-if="
                data.item.user_transaction_status_id === 2 ||
                data.item.user_transaction_status_id === 3
              "
              class="status-void"
            >
              Void/Returned
            </div>
            <div
              v-else-if="data.item.user_transaction_status_id === 4"
              class="text-danger"
            >
              {{ data.item.user_tranasction_status }}
            </div>
          </div>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="changeRecord"
        @pagination="pagination"
        :filter="filter"
        :rows="rows"
      />
    </div>
  </div>
</template>

<script>
import InputRange from "@/components/inputs/InputRange";
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";
export default {
  name: "MissionReport",
  components: {
    InputRange,
    OtherLoading,
  },

  validations: {},

  data() {
    return {
      isLoading: false,
      isBusy: false,
      data: {},
      sidebarShow: false,
      id: parseInt(this.$route.params.id),
      filter: {
        extraPointId: parseInt(this.$route.params.id),
        search: "",
        page: 1,
        take: 10,
      },

      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      fields: [
        {
          key: "invoice_no",
          label: "Pos/Mim No.",
          thClass: "text-nowrap",
        },
        {
          key: "member_id",
          label: "Member ID/Cusotmer Name",
          thClass: "text-nowrap",
        },
        {
          key: "created_time",
          label: "Days/Time Spent",
          thClass: "text-nowrap",
        },
        {
          key: "grand_total",
          label: "Grand Total",
          thClass: "text-nowrap",
        },
        {
          key: "point",
          label: "Point",
          thClass: "text-nowrap",
        },
        {
          key: "extra_point",
          label: "Extra Point",
          thClass: "text-nowrap",
        },
        {
          key: "sales_name",
          label: "Seller Name",
          thClass: "text-nowrap",
        },
        {
          key: "branch_name",
          label: "Branch Name",
          thClass: "text-nowrap",
        },
        {
          key: "source_name",
          label: "Source",
          thClass: "text-nowrap",
        },
        {
          key: "user_transaction_status_id",
          label: "Status",
          thClass: "text-nowrap",
        },
      ],
      reportList: [],

      analysis: {
        total_customer: 0,
        total_complete: 0,
        persent_complete: 0,
        average_progress: 0,
        redeem_reward_customer: 0,
        persent_redeem_reward_customer: 0,
      },
    };
  },
  computed: {
    formartedItems() {
      if (!this.reportList) return [];
      return this.reportList.map((item) => {
        item._rowVariant = item.deleted ? "danger border-top-unset" : "";
        return item;
      });
    },
  },
  created() {
    this.getDetail();

    this.getAnalysis();
  },
  methods: {
    async getAnalysis() {
      // this.isBusy = true;

      const resp = await this.axios(
        `/ExtraPoint/report/analysis/${this.$route.params.id}`
      );

      this.analysis = resp.data.detail;
    },
    async getDetail() {
      this.isBusy = true;

      const resp = await this.axios.post(
        "/ExtraPoint/report/table",
        this.filter
      );

      this.filterDetail = [];
      const data = resp.data;
      this.rows = data.detail.total;
      this.reportList = data.detail.data;

      this.isBusy = false;
    },

    async exportData() {
      // this.isLoading = true;
      try {
        this.$bus.$emit("showLoading");
        const { data } = await this.axios.post(
          "/ExtraPoint/report/table/export",
          this.filter,
          {
            responseType: "blob",
          }
        );
        // if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data])); // change object.
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `extra-point-` + this.$route.params.id + "-report" + `.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$bus.$emit("hideLoading");
      } catch (error) {
        this.errorAlert(error.message);
      }
    },

    pagination(page) {
      this.filter.page = page;
      this.getDetail();
    },

    changeRecord() {
      this.getDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
label {
  font-weight: bold;
}
.pre-box {
  background: var(--secondary-color) !important;
  color: var(--font-color) !important;
}

.cb-table {
  position: relative;
  margin-left: 15px;
  padding-top: 15px;
}
::v-deep .btn-secondary.dropdown-toggle {
  background-color: white;
  border-color: var(--primary-color);
}
.text-gray {
  color: gray;
}
.current-mission {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
